'use client'

import { useRef, useState } from 'react'
import { cn } from '~/utils/cn'
import { Button, ButtonKind, ButtonWidth } from '../../button'
import { Icon } from '../../icon'
import { FileItem } from './components/file-item'
import { FileInputProps } from './file-input.types'

export const FileInput = ({
  accept,
  name,
  isMultiple,
  label,
  onFileChange,
  onFileRemoval,
  error: formError,
  children,
  buttonWidth = ButtonWidth.full,
  isStandalone = false,
  kind,
  showFileNames = true,
  isLoading,
  disable,
  defaultFiles,
}: FileInputProps) => {
  const [files, setFiles] = useState<File[]>(defaultFiles ?? [])
  const [error, setError] = useState<string>('')
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileChange = (newFiles: FileList) => {
    const fileArray = Array.from(newFiles)

    try {
      onFileChange?.(newFiles)
      isMultiple ? setFiles((prev) => [...prev, ...fileArray]) : setFiles(fileArray)
    } catch (e) {
      setError('Please try again, something wrong happened!')
    }
  }

  const handleRemove = (index: number) => {
    const filteredFiles = files.filter((_, i) => i !== index)
    setFiles(filteredFiles)
    onFileRemoval?.()
  }

  const errorMessage = formError || error

  return (
    <div className='flex flex-col'>
      <div>
        <input
          type='file'
          name={name}
          accept={accept}
          multiple={isMultiple}
          className='hidden'
          id='fileInput'
          data-testid='file-input'
          onChange={(event) => {
            if (event.target.files) {
              handleFileChange(event.target.files)
              if (fileInputRef.current) {
                fileInputRef.current.value = ''
              }
            }
          }}
          ref={fileInputRef}
          disabled={disable}
        />

        {(!showFileNames || (showFileNames && !files?.length)) &&
          (children ?? (
            <Button
              {...(isStandalone ? { isStandalone: true } : { kind: kind ? kind : ButtonKind.tertiary })}
              className={cn('relative', buttonWidth === ButtonWidth.full && 'w-full')}
              width={buttonWidth}
              type='button'
              asChild
              loading={isLoading}
              disabled={disable}
              onClick={() => {
                fileInputRef.current?.click()
              }}
            >
              <span className='flex w-full cursor-pointer items-baseline justify-center gap-2'>
                {label}
                <Icon icon='fa-regular fa-upload' />
              </span>
            </Button>
          ))}
      </div>
      <div>
        {showFileNames &&
          files?.map?.(({ name }, index) => (
            <FileItem
              key={name}
              name={name}
              handleRemove={() => handleRemove(index)}
            />
          ))}
      </div>
      {errorMessage && (
        <div
          className='mt-6 text-left text-xs text-error'
          hidden={!errorMessage}
        >
          {errorMessage}
        </div>
      )}
    </div>
  )
}
